import styled from "styled-components"

export const ButtonShaped = styled.button`
  width: 7vw;
  height: 5vh;
  font-size: 2vh;

  margin-left: 1vw;
  margin-right: 1vw;

  background: linear-gradient(
    45deg,
    transparent 5%,
    ${(props) => props.theme.colors.interact} 5%
  );

  border: 0;
  color: ${(props) => props.theme.colors.text};

  font-weight: bold;

  box-shadow: 6px 0px 0px ${(props) => props.theme.colors.primary};

  :hover {
    background: linear-gradient(
      45deg,
      transparent 5%,
      ${(props) => props.theme.colors.hover} 5%
    );
  }

  cursor: pointer;
`
export const Button = styled.button<{ dark?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vh;
  font-size: 2vh;

  padding: 0 1vw;

  background: ${(props) =>
    props.dark ? props.theme.colors.primary : props.theme.colors.interact};
  color: ${(props) => props.theme.colors.text};

  border-radius: 0.5vw;
  border-width: 0;

  font-weight: bold;

  transition: background-color 0.3s;

  :disabled {
    cursor: not-allowed;
  }

  :disabled,
  :hover {
    background: ${(props) =>
      props.dark ? props.theme.colors.darkPrimary : props.theme.colors.hover};
  }

  cursor: pointer;
`

export const Spinner = styled.div<{ dark?: boolean }>`
  display: inline-block;
  width: 4vh;
  height: 4vh;

  :after {
    content: " ";
    display: block;
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    border: 0.4vh solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
