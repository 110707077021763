import { Navigate, Outlet } from "react-router-dom"

interface Props {
  roles: string[]
}

const PrivateRoute = ({ roles }: Props) => {
  const user = { name: "xxxx", roles: ["1", "2"] }
  const userHasRequiredRole = true
  // const userHasRequiredRole = user &&  user.roles.filter(role => roles.includes(role)).length > 0

  return userHasRequiredRole ? (
    <Outlet />
  ) : (
    <Navigate to="/" /> //TODO: Acesso negado
  )
}

export default PrivateRoute
