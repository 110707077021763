import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.colors.card};
  border-radius: 8px;

  align-items: center;
  justify-content: center;
  padding: 1rem;
`

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
`
