import { createTheme } from "@material-ui/core/styles"

export const muitheme = createTheme({
  palette: {
    primary: {
      light: "#22303D",
      main: "#102733",
      dark: "#15202B",
      contrastText: "#fff",
    },
    secondary: {
      light: "#0071b8",
      main: "#1DA1F2",
      dark: "#00568b",
      contrastText: "#000",
    },
  },
})
