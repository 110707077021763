import { lazy } from "react"

import { BrowserRouter, Route, Routes } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"

import Header from "../components/Header"

import Home from "../pages/Home"
import PortifolioOptimizer from "../pages/PortifolioOptimizer"
import { useInterceptor } from "../hooks/useInterceptor"

const Router = () => {
  useInterceptor()
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<PrivateRoute roles={["PortifolioOptimizer"]} />}>
          <Route path="portifoliooptimizer" element={<PortifolioOptimizer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
