import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import IOptimizer from "../../../interfaces/PortifolioOptimizer/optimizer"
import theme from "../../../styles/theme"

interface IProps {
  headersLabels: string[]
  data: IOptimizer[]
}

export default function TableResults({ headersLabels, data }: IProps) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: 650,
        minWidth: 400,
        background: theme.colors.interact,
        marginTop: 3,
        marginBottom: 3,
      }}
    >
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            {headersLabels.map((label) => (
              <TableCell
                sx={{ color: theme.colors.text, fontWeight: "bold" }}
                key={label}
                align="center"
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.asset}>
              <TableCell sx={{ color: theme.colors.text }} align="center">
                {row.asset}
              </TableCell>
              <TableCell sx={{ color: theme.colors.text }} align="center">
                {row.lots}
              </TableCell>
              <TableCell sx={{ color: theme.colors.text }} align="center">
                {row.lotsAdjust}
              </TableCell>
              <TableCell sx={{ color: theme.colors.text }} align="center">
                {row.lotsOptimized}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
