import { ResponsiveTreeMap } from "@nivo/treemap"
import theme from "../../../styles/theme"

interface IProps {
  data: any
  identity: string
  value: string
}

export const TreeMapChart: React.FC<IProps> = ({ data, identity, value }) => {
  return (
    <div style={{ height: "40vh", width: "100%" }}>
      <ResponsiveTreeMap
        data={data}
        identity={identity}
        value={value}
        valueFormat=".02s"
        colors={{ scheme: theme.colors.graphs as any }}
        label={(e) => e.id + " (" + e.formattedValue + ")"}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        labelSkipSize={12}
        labelTextColor={{
          from: "color",
          modifiers: [["brighter", 3]],
        }}
        parentLabelPosition="left"
        parentLabelSize={40}
        parentLabelTextColor={{
          from: "color",
          modifiers: [["brighter", 3]],
        }}
        nodeOpacity={0.25}
        borderColor={{
          from: "color",
          modifiers: [["opacity", 1.8]],
        }}
        theme={{
          textColor: theme.colors.text,
          tooltip: {
            container: {
              background: theme.colors.card,
            },
          },
        }}
      />
    </div>
  )
}
