import styled from "styled-components"

export const Container = styled.div`
  padding: 0 2rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 1rem;
  .buttonAnalyse {
    margin: 1rem 0;
    width: 35rem;
  }
`

export const ContainerDataSelct = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const ContainerInput = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;

  h2 {
    text-align: center;
  }

  form button {
    margin-top: 1vh;
    width: 100%;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`

export const ContentDataSelected = styled.div`
  display: flex;
  flex-wrap: wrap;

  max-width: 320px;

  max-height: 230px;

  overflow-y: auto;

  div {
    display: flex;
    align-items: center;

    justify-content: space-between;

    background-color: ${(props) => props.theme.colors.darkPrimary};
    padding: 0.25rem;

    border-radius: 4px;

    width: 100%;
    max-width: 140px;
  }

  gap: 0.5rem;

  p {
    width: 100%;
    max-width: 50;
  }

  button {
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.text};

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: color 0.3s;

    :hover {
      color: ${(props) => props.theme.colors.redError};
    }
  }

  .icon {
    height: 1.2rem;
    width: 1.2rem;
  }
`

export const ContainerSelect = styled.ul`
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: ${(props) => props.theme.colors.interact};

  width: 300px;
  margin-top: 8.5vh;

  div {
    padding: 0.25rem 1rem;

    :hover {
      background-color: ${(props) => props.theme.colors.hover};
    }

    cursor: pointer;
  }
`
export const ContentInputTkr = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;

  .closeIcon {
    position: absolute;
    right: 15px;
    height: 3vh;
    width: 3vh;
  }
`
