import { FaBars } from "react-icons/fa"
import { NavLink as Link } from "react-router-dom"
import styled from "styled-components"

export const Container = styled.div`
  background: ${(props) => props.theme.colors.card};
  height: 10vh;
  padding: 1vh 4vw;
  display: flex;
  align-items: center;
  box-shadow: 0px 0.4vh 0.8vh black;

  .btnnav {
    margin: 0 1vw;
  }
`

export const ContainerLogo = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

export const ContainerNav = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const ImgLogo = styled.img`
  height: 6vh;
  cursor: pointer;
`

export const ContainerAvatar = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  width: 5vh;
  min-width: 5vh;
  height: 5vh;
  min-height: 5vh;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  :hover .containerDropdown {
    display: block;
    margin-top: 10vh;
    margin-right: 7vh;
  }
`

export const Avatar = styled.p`
  text-align: center;
  font-size: 3vh;
  font-weight: bold;
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  /* Second Nav */
  /* margin-right: 24px; */

  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const ContainerDropdown = styled.ul`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.interact};
`

export const ItemDropdown = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    height: 3vh;
    margin-right: 1vw;
  }

  p {
    font-size: 2vh;
  }

  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};
  }
`

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;

  @media screen and (max-width: 768px) {
    display: flex;
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const ContainerBarNav = styled.div`
  :hover .containerDropdownNav {
    display: block;
  }
`
