import React from "react"

import GlobalStyle from "./styles/global"
import { ThemeProvider } from "styled-components"
import theme from "./styles/theme"
import Router from "./routes/Router"
import { Authenticator, Image, useTheme, View } from "@aws-amplify/ui-react"
import "./App.css"
import "@aws-amplify/ui-react/styles.css"
import Logo from "./assets/Logo.png"
import { MuiThemeProvider } from "@material-ui/core"
import { muitheme } from "./styles/miutheme"

const components = {
  Header() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Logo" src={Logo} />
      </View>
    )
  },
}

const App: React.FC = ({ signOut, user }: any) => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={muitheme}>
      <Authenticator components={components}>
        <Router />
        <GlobalStyle />
      </Authenticator>
    </MuiThemeProvider>
  </ThemeProvider>
)

export default App
