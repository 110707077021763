import styled from "styled-components"

export const RowTopLeft = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-start;
`
export const RowTopRight = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-end;
`
export const RowTopCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: center;
`
export const RowTopBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: space-between;
`
export const RowBottomLeft = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-start;
`
export const RowBottomRight = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-end;
`
export const RowBottomCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: center;
`
export const RowBottomBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: space-between;
`
export const RowCenterLeft = styled.div`
  display: flex;
  height: max-content;
  align-items: center;
  justify-content: flex-start;
`
export const RowCenterRight = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: flex-end;
`
export const RowCenterCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`
export const RowCenterBetween = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const RowCenterBetweenWrap = styled.div`
  display: flex;
  flex: 1;
  height: max-content;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

// COLUMNS
export const ColumnLeftTop = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`
export const ColumnLeftCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const ColumnLeftBottom = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
`
export const ColumnLeftBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-content: flex-start;
  justify-content: space-between;
  flex-direction: column;
`
export const ColumnCenterTop = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`
export const ColumnCenterCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ColumnCenterBottom = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`
export const ColumnCenterBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`
export const ColumnRightTop = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
`
export const ColumnRightCenter = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
`
export const ColumnRightBottom = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
`
export const ColumnRightBetween = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
`
