import { ResponsiveRadar } from "@nivo/radar"
import theme from "../../../styles/theme"

interface IProps {
  data: any
  keys: string[]
  index: string
}

export const RadarChart: React.FC<IProps> = ({ data, keys, index }) => {
  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <ResponsiveRadar
        data={data}
        keys={keys}
        indexBy={index}
        valueFormat=">-.1f"
        margin={{ top: 50, right: 20, bottom: 30, left: 20 }}
        borderColor={{ from: "color" }}
        gridLabelOffset={20}
        dotSize={10}
        fillOpacity={0.35}
        dotColor={{ theme: "background" }}
        dotBorderWidth={1}
        colors={{ scheme: theme.colors.graphs as any }}
        theme={{
          textColor: theme.colors.text,
          tooltip: {
            container: {
              background: theme.colors.card,
            },
          },
        }}
        motionConfig="wobbly"
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: theme.colors.text,
            symbolSize: 12,
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: theme.colors.secText,
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}
