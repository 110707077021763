import React, { useEffect, useState, FormEvent } from "react"
import IPortifolio from "../../interfaces/PortifolioOptimizer/portifolio"
import ITicker from "../../interfaces/PortifolioOptimizer/tickers"
import IOptimizer from "../../interfaces/PortifolioOptimizer/optimizer"
import { apiPrivate } from "../../services/apiPrivate"
import { ColumnLeftTop } from "../../styles/containers"
import {
  Container,
  ContainerDataSelct,
  ContainerInput,
  ContainerSelect,
  ContentDataSelected,
  ContentInputTkr,
} from "./styles"
import { Input } from "../../styles/components/input"
import { Label } from "../../styles/components/label"
import { Button, Spinner } from "../../styles/components/buttons"

import { FiX } from "react-icons/fi"
import { apiPublic } from "../../services/apiPublic"
import { RadarChart } from "../../components/Charts/Radar"
import { TreeMapChart } from "../../components/Charts/TreeMap"
import { BarChart } from "../../components/Charts/Bar"
import TableResults from "./Table"
import { Card } from "../../components/Card"

const PortifolioOptimizer: React.FC = () => {
  const [tickers, setTickers] = useState<string[]>([])
  const [tkrFinder, setTkrFinder] = useState<string>("")
  const [tkrSelected, setTkrSelected] = useState<string>()
  const [lotsInput, setLotsInput] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const [inputPortifolio, setInputPortifolio] = useState<IPortifolio[]>([])
  const [portifolio, setPortifolio] = useState<IOptimizer[]>([])

  useEffect(() => {
    apiPublic
      .get("https://public-bi-data.s3.amazonaws.com/Quotes/tickers.json")
      .then((response) => {
        const listOfTickers = response.data as ITicker[]
        setTickers(listOfTickers.map((tkr) => tkr.name))
      })
  }, [])

  function handleAnalysePortifolio() {
    setLoading(true)
    apiPrivate
      .post(
        "https://6hfyw2esb1.execute-api.us-east-1.amazonaws.com/Stage/portoptmize",
        inputPortifolio
      )
      .then((response) => {
        setPortifolio(response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  function handleGroupNotionalCluster(data: IOptimizer[]) {
    var result = [] as any
    for (var tkr in data) {
      const group = "Cluster: " + data[tkr].cluster
      if (!result.find((pos: any) => pos.cluster === group)) {
        result = [
          ...result,
          {
            cluster: group,
            Current: 0,
            Optimized: 0,
          },
        ]
      }
      const idx = result.findIndex((pos: any) => pos.cluster === group)
      result[idx].Current += data[tkr].notionalPercentage * 100
      result[idx].Optimized += data[tkr].notionalPercentageOptimized * 100
    }
    return result
  }

  function handlePrepareDataToTreeGraph(data: IOptimizer[], otimized = false) {
    const treeData = {
      name: "Portifólio " + (otimized ? "Optimized" : "Current"),
      children: [] as any,
    }

    for (var tkr in data) {
      const group = "Cluster: " + data[tkr].cluster
      if (!treeData.children.find((pos: any) => pos.name === group)) {
        treeData.children = [
          ...treeData.children,
          { name: group, children: [] },
        ]
      }
      const idx = treeData.children.findIndex((pos: any) => pos.name === group)
      treeData.children[idx].children.push({
        name: data[tkr].asset,
        value: otimized ? data[tkr].notionalOptimized : data[tkr].notional,
      })
    }
    return treeData
  }

  function handleAddTkr(event: FormEvent) {
    event.preventDefault()

    if (lotsInput && tkrSelected) {
      setInputPortifolio([
        ...inputPortifolio.filter((tkr) => tkr.tkr !== tkrSelected),
        { tkr: tkrSelected, lots: parseInt(lotsInput) },
      ])
      setTkrSelected(undefined)
      setLotsInput("")
      setTkrFinder("")
    }
  }

  return (
    <Container>
      <h2>Enter your tickers</h2>
      <ContainerDataSelct>
        <ContainerInput>
          <form onSubmit={handleAddTkr}>
            <ColumnLeftTop>
              <Label htmlFor="inputTicker">Ticker*</Label>
              <ContentInputTkr>
                <Input
                  id="inputTicker"
                  placeholder="Ticker"
                  value={tkrFinder}
                  onChange={(e) => setTkrFinder(e.target.value)}
                  disabled={tkrSelected === tkrFinder}
                />
                {tkrSelected === tkrFinder && (
                  <FiX
                    onClick={() => {
                      setTkrSelected(undefined)
                      setTkrFinder("")
                    }}
                    className="closeIcon"
                  />
                )}
              </ContentInputTkr>
              {tkrFinder.length > 0 && tkrSelected !== tkrFinder && (
                <ContainerSelect>
                  {tickers
                    .filter((tkr) =>
                      tkr.toLowerCase().includes(tkrFinder.toLowerCase())
                    )
                    .slice(0, 10)
                    .map((tkr, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setTkrSelected(tkr)
                          setTkrFinder(tkr)
                        }}
                      >
                        <p>{tkr}</p>
                      </div>
                    ))}
                </ContainerSelect>
              )}
            </ColumnLeftTop>

            <Label htmlFor="inputLots">Lots*</Label>

            <Input
              id="inputLots"
              placeholder="Lots"
              value={lotsInput}
              type="number"
              onChange={(e) => setLotsInput(e.target.value)}
              min="1"
            />
            <Button dark type="submit">
              Add
            </Button>
          </form>
        </ContainerInput>
        <ContentDataSelected>
          {inputPortifolio.map((tkr) => (
            <div>
              <p>
                <span title="Ticker">{tkr.tkr}, </span>
                <span title="Lots">{tkr.lots}</span>
              </p>
              <button
                onClick={() => {
                  setInputPortifolio(
                    inputPortifolio.filter((port) => port.tkr !== tkr.tkr)
                  )
                }}
              >
                <FiX className="icon" />
              </button>
            </div>
          ))}
        </ContentDataSelected>
      </ContainerDataSelct>
      {inputPortifolio.length > 0 && (
        <Button
          className="buttonAnalyse"
          dark
          disabled={loading}
          onClick={() => handleAnalysePortifolio()}
        >
          {loading ? <Spinner /> : "Analyse"}
        </Button>
      )}
      {portifolio.length > 0 && (
        <>
          <Card title="Clusters portifolio">
            <RadarChart
              data={handleGroupNotionalCluster(portifolio)}
              keys={["Current", "Optimized"]}
              index="cluster"
            />
          </Card>
          <Card title="Current portifolio">
            <TreeMapChart
              data={handlePrepareDataToTreeGraph(portifolio, false)}
              identity="name"
              value="value"
            />
          </Card>
          <Card title="Optimized portifolio">
            <TreeMapChart
              data={handlePrepareDataToTreeGraph(portifolio, true)}
              identity="name"
              value="value"
            />
          </Card>

          <Card title="Tickers Risk">
            <BarChart
              data={portifolio.map((tkr) => ({
                asset: tkr.asset,
                Current: tkr.riskContribution * 100,
                Otimizado: tkr.riskContributionOptimized * 100,
              }))}
              keys={["Current", "Otimizado"]}
              indexBy="asset"
              legendX="Alocation (%)"
              legendY="Ticker"
            />
          </Card>
          <TableResults
            headersLabels={[
              "Ticker",
              "Current Lots",
              "Adjust",
              "Optimized Lots",
            ]}
            data={portifolio}
          />
        </>
      )}
    </Container>
  )
}

export default PortifolioOptimizer
