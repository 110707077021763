import { useAuthenticator } from "@aws-amplify/ui-react"
import { apiPrivate } from "../services/apiPrivate"

export const useInterceptor = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user])

  apiPrivate.interceptors.request.use((config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization:
          "Bearer " +
          user.getSignInUserSession()?.getAccessToken().getJwtToken(),
      },
    }
  })
}
