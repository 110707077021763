import styled from "styled-components"

export const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  height: 3rem;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.dark2Primary};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.interact};

  color: ${(props) => props.theme.colors.text};

  transition: border 0.3s;

  :focus,
  :hover {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.primary};
  }

  :disabled {
    filter: brightness(0.8);
    cursor: not-allowed;
    border: 2px solid ${(props) => props.theme.colors.dark2Primary};
  }
`
