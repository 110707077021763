const theme = {
  colors: {
    background: "#15202B",
    card: "#102733",
    interact: "#22303D",
    hover: "#293a49",
    text: "#fff",
    secText: "#8899A5",
    primary: "#1DA1F2",
    darkPrimary: "#0071b8",
    dark2Primary: "#00568b",
    lightPrimary: "#54b6f3",
    redError: "#ff1c1c",
    dark: "#000",
    graphs: "dark2",
  },
}

export default theme
