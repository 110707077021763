import React from "react"
import { Link } from "react-router-dom"
import { RowTopLeft, RowTopRight } from "../../styles/containers"
import { useAuthenticator } from "@aws-amplify/ui-react"
import Logo from "../../assets/Logo.png"
import { FaSignOutAlt, FaWallet } from "react-icons/fa"
import {
  Bars,
  NavMenu,
  ImgLogo,
  Container,
  ContainerLogo,
  Avatar,
  ContainerAvatar,
  ContainerNav,
  ContainerDropdown,
  ItemDropdown,
  ContainerBarNav,
} from "./styles"
import { Button } from "../../styles/components/buttons"

const pages = [{ name: "Portifolio Optimization", url: "portifoliooptimizer" }]
const Header: React.FC = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user])

  return (
    <Container>
      <ContainerBarNav>
        <Bars />
        <ContainerDropdown className="containerDropdownNav">
          <ItemDropdown>
            <Link to="/portifoliooptimizer" className="link">
              <FaWallet className="icon" />
              <p>Portifolio Optimizator</p>
            </Link>
          </ItemDropdown>
        </ContainerDropdown>
      </ContainerBarNav>
      <ContainerLogo>
        <Link to="/">
          <ImgLogo src={Logo} alt="logo" />
        </Link>
      </ContainerLogo>
      <ContainerNav>
        <Link to="/portifoliooptimizer">
          <Button className="btnnav">Portifolio Optimizator</Button>
        </Link>
      </ContainerNav>
      <ContainerAvatar>
        <Avatar>{user.attributes?.name[0].toUpperCase()}</Avatar>
        <ContainerDropdown className="containerDropdown">
          <ItemDropdown onClick={() => signOut()}>
            <FaSignOutAlt className="icon" />
            <p>Logout</p>
          </ItemDropdown>
        </ContainerDropdown>
      </ContainerAvatar>
    </Container>
  )
}

export default Header
