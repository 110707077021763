import { Container, Title } from "./styles"

interface IProps {
  children: React.ReactNode
  title?: string
}

export function Card({ children, title }: IProps) {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  )
}
