import { ResponsiveBar } from "@nivo/bar"
import theme from "../../../styles/theme"

interface IProps {
  data: any[]
  keys: string[]
  indexBy: string
  legendX: string
  legendY: string
}

export const BarChart: React.FC<IProps> = ({
  data,
  keys,
  indexBy,
  legendX,
  legendY,
}) => {
  return (
    <div style={{ height: data.length * 50, width: "100%" }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
        padding={0.3}
        groupMode="grouped"
        layout="horizontal"
        valueFormat=".02s"
        label={(e) => e.value?.toFixed(1) + "%"}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: theme.colors.graphs as any }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.7]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legendX,
          legendPosition: "middle",
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 3,
          tickPadding: 3,
          tickRotation: 0,
          legend: legendY,
          legendPosition: "middle",
          legendOffset: -70,
        }}
        enableGridX={true}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["brighter", 3]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        theme={{
          textColor: theme.colors.text,
          tooltip: {
            container: {
              background: theme.colors.card,
            },
          },
          axis: {
            legend: {
              text: {
                fontSize: 16,
                fill: theme.colors.text,
              },
            },
            ticks: {
              line: {
                stroke: theme.colors.text,
                strokeWidth: 1,
              },
              text: {
                fontSize: 11,
                fill: theme.colors.text,
              },
            },
          },
          labels: {},
        }}
      />
    </div>
  )
}
