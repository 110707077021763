import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background:  ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.hover};
  }
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, ${(props) =>
      props.theme.colors.darkPrimary}, ${(props) =>
  props.theme.colors.primary});
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
  }
`
